import { usePageData } from '@hooks/usePageData';
import { Col, Row } from 'antd';
import { Autoscout24Title } from './Autoscout24Title';
import { MobileTitle } from './MobileTitle';
import cn from './styles.less';

export const Title = () => {
  const { data, isLoading } = usePageData();

  if (isLoading || !data) return <></>;

  const { autoscout24, mobiledeV2 } = data.adMgmt;

  return (
    <Row
      gutter={[16, 16]}
      data-qa-selector="classifieds-description"
      className={cn.title}
    >
      {autoscout24?.data ? (
        <Col span={24} data-qa-selector="classifieds-title-autoscout24-panel">
          <Autoscout24Title />
        </Col>
      ) : null}
      {mobiledeV2?.data ? (
        <Col span={24} data-qa-selector="classifieds-title-mobile-panel">
          <MobileTitle />
        </Col>
      ) : null}
    </Row>
  );
};
