/* istanbul ignore file */
import { FeatureDetailType, GeneralSection } from '@gql_codegen/retail-types';
import {
  REQUIRED_VALIDATION_MESSAGE,
  SW_DOT_LENGTH_MESSAGE,
  MAX_24_LENGTH_MESSAGE,
} from '@src/constants';
import { FormType } from '@src/layout';
import { DeepPartial } from 'react-hook-form';
import * as z from 'zod';
import { get } from 'lodash';
import { GROSS_PRICE_PATH } from '@sections/General/CarData/GrossPrice/constants';

const IMAGES_ARRAY = z
  .object({
    id: z.string().nonempty(),
    nonRemovable: z.boolean().nullable(),
    original: z.string().nullable(),
    thumbnail: z.string().nullable(),
    uploadedUrl: z.string().nullable(),
    tags: z
      .object({
        id: z.string().nullable(),
        tagImageId: z.string().nonempty(),
        position: z.object({
          top: z.number(),
          left: z.number(),
        }),
      })
      .array()
      .nullable(),
    photoId: z.number().nullable(),
  })
  .array();

type GetFormSchema = (
  arg: GeneralSection | undefined,
) => (values: FormType) => z.ZodSchema<DeepPartial<FormType>>;

export const getFormSchema: GetFormSchema =
  (
    adMgmtGeneral, // FIX-ME this is a very coupled way of removing things from schema. Need better solution.
  ) =>
  (values) => {
    const {
      canSeeCarType,
      canSeeManufacturerCode,
      canSeeModelCode,
      canSeeBpm,
      canSeeFinancedPrice,
    } = adMgmtGeneral?.restrictions || {};
    //TODO: make TS mapped types for it and remove DeepPartial.

    const FormSchema: z.ZodSchema<DeepPartial<FormType>> = z.object({
      adMgmt: z.object({
        autoheroAndWkda: z
          .object({
            media: z
              .object({
                data: z
                  .object({
                    images: z
                      .object({
                        nextgen_highlight: IMAGES_ARRAY,
                        interior: IMAGES_ARRAY,
                        exterior: IMAGES_ARRAY,
                        panorama: IMAGES_ARRAY,
                      })
                      .nullable(),
                  })
                  .nullable(),
              })
              .nullable(),
            secondaryWheels: z
              .object({
                data: z
                  .object({
                    isActive: z.boolean(),
                    netPrice: z.number().nullable().optional(),
                    isShippingRequired: z.boolean(),
                    location: z.string().nullable().optional(),
                    damageCondition: z.string({
                      required_error: REQUIRED_VALIDATION_MESSAGE,
                      invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
                    }),
                    grossPrice: z.number({
                      invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
                    }),
                    vatRate: z.number({
                      invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
                    }),
                    dot: z.object({
                      frontLeft: z.z
                        .string()
                        .regex(/^\d{0,4}$/, { message: 'Should be numbers' })
                        .length(4, { message: SW_DOT_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                      frontRight: z
                        .string()
                        .regex(/^\d{0,4}$/, { message: 'Should be numbers' })
                        .length(4, { message: SW_DOT_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                      rearLeft: z
                        .string()
                        .regex(/^\d{0,4}$/, { message: 'Should be numbers' })
                        .length(4, { message: SW_DOT_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                      rearRight: z
                        .string()
                        .regex(/^\d{0,4}$/, { message: 'Should be numbers' })
                        .length(4, { message: SW_DOT_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                    }),
                    manufacturer: z.object({
                      frontLeft: z
                        .string()
                        .max(24, { message: MAX_24_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                      frontRight: z
                        .string()
                        .max(24, { message: MAX_24_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                      rearLeft: z
                        .string()
                        .max(24, { message: MAX_24_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                      rearRight: z
                        .string()
                        .max(24, { message: MAX_24_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                    }),
                    material: z.object({
                      frontLeft: z.string().nullable().optional(),
                      frontRight: z.string().nullable().optional(),
                      rearLeft: z.string().nullable().optional(),
                      rearRight: z.string().nullable().optional(),
                    }),
                    radius: z.object({
                      frontLeft: z.number().nullable().optional(),
                      frontRight: z.number().nullable().optional(),
                      rearLeft: z.number().nullable().optional(),
                      rearRight: z.number().nullable().optional(),
                    }),
                    seasonType: z.object({
                      frontLeft: z.string().nullable().optional(),
                      frontRight: z.string().nullable().optional(),
                      rearLeft: z.string().nullable().optional(),
                      rearRight: z.string().nullable().optional(),
                    }),
                    treadDepth: z.object({
                      frontLeft: z.number().nullable().optional(),
                      frontRight: z.number().nullable().optional(),
                      rearLeft: z.number().nullable().optional(),
                      rearRight: z.number().nullable().optional(),
                    }),
                    wheelWidth: z.object({
                      frontLeft: z.number().nullable().optional(),
                      frontRight: z.number().nullable().optional(),
                      rearLeft: z.number().nullable().optional(),
                      rearRight: z.number().nullable().optional(),
                    }),
                    wheelHeight: z.object({
                      frontLeft: z.number().nullable().optional(),
                      frontRight: z.number().nullable().optional(),
                      rearLeft: z.number().nullable().optional(),
                      rearRight: z.number().nullable().optional(),
                    }),
                    loadIndex: z.object({
                      frontLeft: z.number().nullable().optional(),
                      frontRight: z.number().nullable().optional(),
                      rearLeft: z.number().nullable().optional(),
                      rearRight: z.number().nullable().optional(),
                    }),
                    speedIndex: z.object({
                      frontLeft: z
                        .string()
                        .max(24, { message: MAX_24_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                      frontRight: z
                        .string()
                        .max(24, { message: MAX_24_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                      rearLeft: z
                        .string()
                        .max(24, { message: MAX_24_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                      rearRight: z
                        .string()
                        .max(24, { message: MAX_24_LENGTH_MESSAGE })
                        .nullable()
                        .optional(),
                    }),
                    rimsDetails: z.object({
                      frontLeft: z
                        .object({
                          label: z.string().nullable().optional(),
                          value: z.string().nullable().optional(),
                        })
                        .array()
                        .nullable()
                        .optional(),
                      frontRight: z
                        .object({
                          label: z.string().nullable().optional(),
                          value: z.string().nullable().optional(),
                        })
                        .array()
                        .nullable()
                        .optional(),
                      rearLeft: z
                        .object({
                          label: z.string().nullable().optional(),
                          value: z.string().nullable().optional(),
                        })
                        .array()
                        .nullable()
                        .optional(),
                      rearRight: z
                        .object({
                          label: z.string().nullable().optional(),
                          value: z.string().nullable().optional(),
                        })
                        .array()
                        .nullable()
                        .optional(),
                    }),
                    tiresDetails: z.object({
                      frontLeft: z
                        .object({
                          label: z.string().nullable().optional(),
                          value: z.string().nullable().optional(),
                        })
                        .array()
                        .nullable()
                        .optional(),
                      frontRight: z
                        .object({
                          label: z.string().nullable().optional(),
                          value: z.string().nullable().optional(),
                        })
                        .array()
                        .nullable()
                        .optional(),
                      rearLeft: z
                        .object({
                          label: z.string().nullable().optional(),
                          value: z.string().nullable().optional(),
                        })
                        .array()
                        .nullable()
                        .optional(),
                      rearRight: z
                        .object({
                          label: z.string().nullable().optional(),
                          value: z.string().nullable().optional(),
                        })
                        .array()
                        .nullable()
                        .optional(),
                    }),
                    ad_secondary_wheels: z
                      .object({
                        id: z.string({
                          required_error: REQUIRED_VALIDATION_MESSAGE,
                        }),
                        original: z.string(),
                        thumbnail: z.string(),
                        uploadedUrl: z.string(),
                      })
                      .nullable()
                      .optional()
                      .array(),
                    ad_secondary_wheels_damages: z
                      .object({
                        id: z.string({
                          required_error: REQUIRED_VALIDATION_MESSAGE,
                        }),
                        original: z.string(),
                        thumbnail: z.string(),
                        uploadedUrl: z.string(),
                      })
                      .nullable()
                      .optional()
                      .array(),
                  })
                  .nullable(),
              })
              .nullable(),
            featureDetails: z.object({
              data: z
                .array(
                  z.object({
                    id: z.string(),
                    name: z.string(),
                    items: z.array(
                      z.object({
                        id: z.string().optional(),
                        uiFeatureDetailIdentifier: z.string().optional(),
                        name: z.string().optional(),
                        translations: z.array(
                          z.object({
                            locale: z.string(),
                            text: z.string(),
                          }),
                        ),
                        subGroup: z
                          .object({
                            name: z.string().min(1, `Can't be empty`),
                          })
                          .optional()
                          .nullable(),
                        group: z.object({
                          name: z.string().min(1, `Can't be empty`),
                        }),
                        isOriginal: z.boolean().nullable(),
                        type: z.nativeEnum(FeatureDetailType).nullable(),
                        isNewTranslation: z.boolean().optional(),
                        subcategory: z
                          .object({
                            name: z.string(),
                            id: z.string(),
                          })
                          .optional()
                          .nullable(),
                      }),
                      //TODO: uncomment after new Equipment UI phase 2
                      // .superRefine((item, ctx) => {
                      //   if (
                      //     item.translations.every(
                      //       (translation) => translation.text.trim() !== '',
                      //     )
                      //   )
                      //     return z.NEVER;

                      //   item.translations.forEach(
                      //     (tranlsation, tranlsationIdx) => {
                      //       const emptyTranslationIdx =
                      //         tranlsation.text.trim() === '';

                      //       if (!emptyTranslationIdx) return z.NEVER;

                      //       ctx.addIssue({
                      //         code: z.ZodIssueCode.too_small,
                      //         minimum: 1,
                      //         type: 'string',
                      //         inclusive: true,
                      //         message: `${item.group.name} ${
                      //           item.subGroup?.name
                      //             ? ` | ${item.subGroup.name}`
                      //             : ''
                      //         } has an empty ${tranlsation.locale} description`,
                      //         path: ['translations', tranlsationIdx],
                      //       });
                      //     },
                      //   );
                      // }),
                    ),
                  }),
                )
                .superRefine((categories, ctx) => {
                  categories.forEach((category, categoryIdx) => {
                    // const categoryItems = category.items.map((item) => ({
                    //   translations: item.translations.reduce((acc, curr) => {
                    //     acc.set(
                    //       `${item?.subcategory?.id ?? ''}${curr.locale}`,
                    //       curr.text,
                    //     );

                    //     return acc;
                    //   }, new Map<string, string>()),
                    // }));

                    category.items.forEach((item, idx) => {
                      //TODO: uncomment after new Equipment UI phase 2
                      // if (
                      //   category.id ===
                      //     EQUIPMENT_CATEGORIES_IDS['additional (unclassified)'] &&
                      //   item.group.name !== INVALID_GROUPS.unclassified
                      // ) {
                      //   //check duplicate
                      //   categories
                      //     .filter(
                      //       (category) =>
                      //         category.id !==
                      //         EQUIPMENT_CATEGORIES_IDS[
                      //           'additional (unclassified)'
                      //         ],
                      //     )
                      //     .find((category) => {
                      //       return category.items.find((posibleDuplicate) => {
                      //         return (
                      //           item.group.name === posibleDuplicate.group.name &&
                      //           item.subGroup?.name ===
                      //             posibleDuplicate.subGroup?.name &&
                      //           item.subcategory?.id ===
                      //             posibleDuplicate.subcategory?.id &&
                      //           item.translations.some(
                      //             (itemTranslation, translationIdx) => {
                      //               const hasSameTranslation =
                      //                 itemTranslation.text ===
                      //                 posibleDuplicate.translations.find(
                      //                   (catTranslation) =>
                      //                     catTranslation.locale ===
                      //                     itemTranslation.locale,
                      //                 )?.text;

                      //               if (hasSameTranslation) {
                      //                 ctx.addIssue({
                      //                   code: z.ZodIssueCode.custom,
                      //                   message: `Duplicate translation for ${itemTranslation.locale}`,
                      //                   path: [
                      //                     categoryIdx,
                      //                     'items',
                      //                     idx,
                      //                     'translations',
                      //                     translationIdx,
                      //                   ],
                      //                 });
                      //               }
                      //               return hasSameTranslation;
                      //             },
                      //           )
                      //         );
                      //       });
                      //     });
                      // }
                      //   //check current category for duplicates
                      //   item.translations.forEach((t, tIdx) => {
                      //     const duplicate = categoryItems.find(
                      //       (it, i) =>
                      //         t.text !== '' &&
                      //         it.translations.has(
                      //           `${item.subcategory?.id ?? ''}${t.locale}`,
                      //         ) &&
                      //         it.translations.get(
                      //           `${item.subcategory?.id ?? ''}${t.locale}`,
                      //         ) === t.text &&
                      //         i !== idx,
                      //     );

                      //     if (duplicate?.translations?.size) {
                      //       ctx.addIssue({
                      //         code: z.ZodIssueCode.custom,
                      //         message: `Duplicate translation for ${t.locale}`,
                      //         path: [
                      //           categoryIdx,
                      //           'items',
                      //           idx,
                      //           'translations',
                      //           tIdx,
                      //         ],
                      //       });
                      //     }
                      //   });
                      // });

                      //check for same featureGroup/SubGroup + Description for duplicates
                      item.translations.forEach(
                        (translation, translationIdx) => {
                          const duplicate = category.items.filter(
                            (categoryItem) =>
                              !categoryItem.uiFeatureDetailIdentifier &&
                              categoryItem.group.name === item.group.name &&
                              categoryItem.subGroup?.name ===
                                item.subGroup?.name &&
                              categoryItem.translations.every(
                                (
                                  categoryItemTranslation,
                                  categoryItemTranslationIdx,
                                ) =>
                                  categoryItemTranslation.text ===
                                  item.translations[categoryItemTranslationIdx]
                                    ?.text,
                              ),
                          );

                          if (duplicate.length > 1) {
                            ctx.addIssue({
                              code: z.ZodIssueCode.custom,
                              message: `Duplicate translation for ${translation.locale}`,
                              path: [
                                categoryIdx,
                                'items',
                                idx,
                                'translations',
                                translationIdx,
                              ],
                            });
                          }
                        },
                      );
                    });
                  });
                }),
            }),
            specsSpotlight: z
              .object({
                data: z.object({
                  topHighlights: z.array(z.string()).max(6),
                  topFeatureDetails: z.array(z.string()).max(6),
                }),
              })
              .nullable(),
          })
          .nullable(),
        mobiledeV2: z
          .object({
            data: z
              .object({
                title: z
                  .string()
                  .max(48, {
                    message:
                      'Exceeded allowed length. Maximum allowed is 48 characters',
                  })
                  .nullable(),
              })
              .nullable(),
          })
          .nullable(),
        autoscout24: z
          .object({
            data: z
              .object({
                title: z
                  .string()
                  .max(50, {
                    message:
                      'Exceeded allowed length. Maximum allowed is 50 characters',
                  })
                  .nullable(),
              })
              .nullable(),
          })
          .nullable(),
        general: z.object({
          data: z.object({
            // Car Data
            model: z.string().nullable().optional(),
            manufacturer: z.string().nullable().optional(),
            status: z.string().nullable().optional(),
            stockNumber: z.string().nullable().optional(),
            vin: z.string().nullable().optional(),
            subType: z
              .string({ required_error: REQUIRED_VALIDATION_MESSAGE })
              .nonempty({ message: REQUIRED_VALIDATION_MESSAGE }),
            subTypeExtra: z.string().nullable().optional(),
            grossPriceMajorUnits: z
              .number({
                required_error: REQUIRED_VALIDATION_MESSAGE,
                invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
              })
              .min(1),
            netPriceMajorUnits: z.number().nullable().optional(),
            vatAmount: z.number().nullable().optional(),
            vatType: z.string().nullable().optional(),
            builtYear: z.number().min(2000).nullable().optional(),
            bodyType: z.number().nullable().optional(),
            gearType: z.number({
              required_error: REQUIRED_VALIDATION_MESSAGE,
              invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
            }),
            primaryWheelSeason: z.string().nullable().optional(),
            registration: z.string().nullable().optional(),
            horsePower: z.number().min(0).max(9999).nullable().optional(),
            seatCount: z.number().min(0).max(99).nullable().optional(),
            handoverKeyCount: z.number({
              required_error: REQUIRED_VALIDATION_MESSAGE,
              invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
            }),
            ...(canSeeCarType && {
              carType: z.string({
                required_error: REQUIRED_VALIDATION_MESSAGE,
                invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
              }),
            }),
            ...(canSeeCarType && {
              taxRating: z.number({
                required_error: REQUIRED_VALIDATION_MESSAGE,
                invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
              }),
            }),
            ...(canSeeManufacturerCode && {
              manufacturerCode: z
                .string()
                .regex(/^\d{0,4}$/, { message: 'HSN must be max 4 digits' })
                .nullable()
                .optional(),
            }),
            ...(canSeeModelCode && {
              modelCode: z.string().nullable().optional(),
            }),
            ...(canSeeBpm && {
              bpmMajorUnits: z.number().max(15000).nullable().optional(),
            }),
            ...(canSeeFinancedPrice && {
              financedPriceMajorUnits: z.number().nullable().optional(),
            }),
            // Vehicle History And Service
            mileage: z.object({
              distance: z
                .number({
                  required_error: REQUIRED_VALIDATION_MESSAGE,
                  invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
                })
                .min(0),
            }),
            uniqueSellingPropositions: z
              .object({
                usp1: z
                  .string()
                  .min(3)
                  .max(25)
                  .nullable()
                  .optional()
                  .or(z.literal('')),
                usp2: z
                  .string()
                  .min(3)
                  .max(25)
                  .nullable()
                  .optional()
                  .or(z.literal('')),
                usp3: z
                  .string()
                  .min(3)
                  .max(25)
                  .nullable()
                  .optional()
                  .or(z.literal('')),
              })
              .refine(
                (val) =>
                  `${val?.usp1?.trim() ?? ''}${val?.usp2?.trim() ?? ''}${
                    val?.usp3?.trim() ?? ''
                  }`.length <= 38,
                {
                  message:
                    'Total character limit of 38 exceeded for all UPSs fields',
                },
              )
              .nullable()
              .optional(),
            carPreownerCount: z.number().nullable().optional(),
            countryOfOrigin: z.string().nullable().optional(),
            lastServiceOn: z.string().nullable().optional(),
            inspectionExpiryDate: z.string().nullable().optional(),
            licensePlate: z.string().nullable().optional(),
            retailCountryLicensePlate: z.string().nullable().optional(),
            originalEngine: z.boolean().nullable().optional(),
            carAttrAccidentBool: z.boolean().nullable().optional(),
            wasInCommercialUse: z.boolean().nullable().optional(),
            // Emission And Consumption
            fuelType: z
              //TODO: when BE fully release this feature and `fuelTypeOptions` values will be strings, remove this number validation
              .number({
                required_error: REQUIRED_VALIDATION_MESSAGE,
                invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
              })
              .int()
              .or(z.string()),
            energyEfficiencyClass: z.string().nullable().optional(),
            emissionSticker: z.string().nullable().optional(),
            emissionClass: z.string().nullable().optional(),
            fuelConsumption: z.object({
              city: z.number().nullable().optional(),
              highway: z.number().nullable().optional(),
              combined: z.number().nullable().optional(),
              common: z.number().nullable().optional(),
            }),
            co2Value: z.number({
              required_error: REQUIRED_VALIDATION_MESSAGE,
              invalid_type_error: REQUIRED_VALIDATION_MESSAGE,
            }),
            kw: z.number().min(0).max(1000).nullable().optional(),
            ccm: z.number().nullable().optional(),
            availableAtShowroom: z.boolean().nullable(),
            branchName: z.string().nullable(),
            doorCount: z.number().min(1).max(9).nullable().optional(),
            oemPaintName: z.string().max(30).nullable().optional(),
            trimLevel: z.string().nullable().optional(),
            carAccidentDamages: z
              .object({
                accidentDamages: z
                  .object({
                    id: z.string(),
                    carPart: z.string(),
                    damageType: z.string(),
                    attributes: z.string(),
                    showToCustomer: z.boolean(),
                    isRepaired: z.boolean().nullable(),
                  })
                  .array(),
                accidentArea: z.string().array(),
              })
              .nullable(),
          }),
        }),
        externalUrls: z
          .object({
            data: z
              .object({
                url: z.union([
                  z
                    .string()
                    .regex(
                      /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
                      'URL is invalid',
                    )
                    .nullish(),
                  z.literal(''),
                ]),
              })
              .nullable(),
          })
          .nullable(),
        exportOverview: z.object({
          exportItems: z
            .object({ comingSoon: z.boolean().nullable().optional() })
            .array(),
          data: z.object({
            publishingBlockers: z
              .object({
                comment: z
                  .string()
                  .nonempty({ message: REQUIRED_VALIDATION_MESSAGE })
                  .max(255),
                reasonId: z
                  .string()
                  .nonempty({ message: REQUIRED_VALIDATION_MESSAGE }),
                subReasonId: z
                  .string()
                  .nonempty({ message: REQUIRED_VALIDATION_MESSAGE }),
              })
              .array(),
          }),
        }),
      }),
      priceChangeReason: z
        .string()
        .optional()
        .refine(
          (schema) => {
            return get(values, GROSS_PRICE_PATH) !==
              adMgmtGeneral?.data.grossPriceMajorUnits
              ? !!schema?.length
              : true;
          },
          { message: "Field can't be empty" },
        ),
      priceChangeComment: z
        .string()
        .optional()
        .refine(
          (schema) => {
            return get(values, GROSS_PRICE_PATH) !==
              adMgmtGeneral?.data.grossPriceMajorUnits
              ? !!schema?.length
              : true;
          },
          { message: "Field can't be empty" },
        ),
    }) as z.ZodSchema<DeepPartial<FormType>>;

    return FormSchema;
  };
