import { usePageData } from '@hooks/usePageData';
import { Typography } from 'antd';

export const CurrentAssignee = () => {
  const { data } = usePageData();
  const assignee = data?.adMgmt?.assignee;

  if (!assignee) {
    return <></>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.1rem 0.2rem',
        lineHeight: '1rem',
      }}
    >
      Assigned to:
      <Typography.Title
        level={5}
        style={{ margin: 0, lineHeight: '1.5rem' }}
        data-qa-selector="current-assignee"
      >
        {assignee}
      </Typography.Title>
    </div>
  );
};
