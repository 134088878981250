import { BellTwoTone } from '@ant-design/icons';
import {
  AdPreparation,
  useGetAdPreparationQuery,
  useUpdatePreparationCommentMutation,
} from '@gql_codegen/retail-types';
import useAdId from '@hooks/useAdId';
import { getServerErrors } from '@utils/getServerErrors';
import { retailKibanaLogger } from '@utils/logger';
import {
  Button,
  Descriptions,
  Popover,
  Input,
  notification,
  Space,
} from 'antd';
import { useState } from 'react';
const { TextArea } = Input;

export const PublishingToDo = (): JSX.Element => {
  const [comment, setComment] = useState<string>('');
  const [isBellIconVisible, setBellIconVisible] = useState(false);
  const adId = useAdId() ?? '';

  const { data, isLoading, isError } = useGetAdPreparationQuery(
    { adId },
    {
      onSuccess: (data) => {
        setComment(data.adPreparation.comment ?? '');
        if (data.adPreparation.comment?.length) {
          setBellIconVisible(true);
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Failed to load Ad Info',
          style: {
            width: '100%',
          },
        });
        const errors = getServerErrors(error);
        retailKibanaLogger.warn(
          'PublishingToDo::useGetAdPreparationQuery -> Error getting ad preparation',
          errors,
        );
      },
    },
  );

  const preparationCommentMutation = useUpdatePreparationCommentMutation({
    onSuccess: (data) => {
      setComment(data.updatePreparationComment.comment ?? '');
      if (data.updatePreparationComment.comment?.length) {
        setBellIconVisible(true);
      } else {
        setBellIconVisible(false);
      }
      notification.success({
        message: 'Ad Info comment has been saved successfully!',
        style: {
          width: '100%',
        },
      });
    },
    onError: (error) => {
      notification.error({
        message: 'Erorr occured during Ad Info comment update',
        style: {
          width: '100%',
        },
      });
      const errors = getServerErrors(error);
      retailKibanaLogger.warn(
        'PublishingToDo::useUpdatePreparationCommentMutation -> Error on mutating Ad Preparation comment',
        errors,
      );
    },
  });

  if (!data || isLoading || isError) return <></>;

  const handleCommentSave = () => {
    preparationCommentMutation.mutate({
      adId,
      comment,
    });
  };

  const getIsFinancedText = (
    isFinanced: AdPreparation['isFinanced'],
  ): string => {
    if (isFinanced === null || typeof isFinanced === 'undefined') return '-';

    if (isFinanced) return 'Financed';
    return 'Not financed';
  };

  const content = (
    <Space direction="vertical">
      <Descriptions bordered column={1} size="small">
        <Descriptions.Item label={'Tuning Comment'}>
          <span data-qa-selector="publishing-todo-tuning-comment-value">
            {data.adPreparation.tuningComment ?? '-'}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label={'Financed State'}>
          <span data-qa-selector="publishing-todo-financed-state-value">
            {getIsFinancedText(data.adPreparation.isFinanced)}
          </span>
        </Descriptions.Item>
        {...data.adPreparation.explainerLinks.map((item) => (
          <Descriptions.Item label={item.label} key={item.label}>
            <Button
              type="link"
              href={item.url ?? ''}
              target="_blank"
              style={{ paddingLeft: 0 }}
              data-qa-selector={`publishing-todo-explainer-link-${item.label}-value`}
            >
              {item.text}
            </Button>
          </Descriptions.Item>
        ))}
      </Descriptions>
      <TextArea
        rows={3}
        placeholder="Comment:"
        maxLength={120}
        showCount
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        disabled={preparationCommentMutation.isLoading}
        data-qa-selector="publishing-todo-comment-input"
      />
      <Button
        size="small"
        onClick={handleCommentSave}
        disabled={preparationCommentMutation.isLoading}
        data-qa-selector="publishing-todo-save-comment-btn"
      >
        Save Comment
      </Button>
    </Space>
  );

  return (
    <div>
      <Popover
        placement="bottom"
        content={content}
        trigger={['click']}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentElement as HTMLElement
        }
      >
        <Button data-qa-selector="publishing-todo-info-btn" size="large">
          Ad Info
          {isBellIconVisible ? <BellTwoTone /> : <></>}
        </Button>
      </Popover>
    </div>
  );
};
