import { PageHeader } from '@ant-design/pro-layout';
import { AdPreparationState } from '@components/AdPreparationState';
import { PublishingToDo } from '@components/PublishingToDo';
import { usePageData } from '@hooks/usePageData';
import { Button, Space, Spin } from 'antd';
import Helmet from 'react-helmet';
import { CurrentAssignee } from './CurrentAssignee';
import cn from './styles.less';

type ActionBarProps = React.PropsWithChildren<{
  saveInProgress: boolean;
}>;

export const usePageTitle = (): string => {
  const { data } = usePageData();
  return data?.adMgmt.title || '';
};

export const ActionBar = ({
  saveInProgress = false,
}: ActionBarProps): JSX.Element => {
  const adTitle = usePageTitle();

  return (
    <>
      <Helmet title={adTitle} />
      <div className={cn.root} data-qa-selector="header-panel">
        <PageHeader
          ghost={false}
          backIcon={false}
          title={
            <>
              <Button
                type="link"
                href="/en/export-overview"
                data-qa-selector="back-to-list"
              >
                <span className={cn.backBtn}>AD MGMT</span>
              </Button>
              <span className={cn.subTitle}>/ </span>
              <span data-qa-selector="adTitle" className={cn.subTitle}>
                {adTitle}
              </span>
            </>
          }
          extra={
            <Space data-qa-selector="header-panel" size="small">
              {saveInProgress && (
                <div
                  className={cn.spinerWrapper}
                  data-qa-selector="isSavingSpiner"
                >
                  <Spin size="large" />
                </div>
              )}
              <CurrentAssignee />
              <PublishingToDo />
              <AdPreparationState />
              <Button
                type="primary"
                size="large"
                data-qa-selector="reset"
                htmlType="reset"
                disabled={saveInProgress}
                danger
              >
                Reset
              </Button>
              <Button
                type="primary"
                size="large"
                data-qa-selector="save"
                htmlType="submit"
                disabled={saveInProgress}
              >
                Save
              </Button>
            </Space>
          }
        ></PageHeader>
      </div>
    </>
  );
};
