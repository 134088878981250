import { useAppForm } from '@hooks/useAppForm';
import { Affix, Button, Col, Row, Select, Space } from 'antd';
import { useState } from 'react';
import { SHOW_EQUIPMENT_STATE, ShowEquipmentState } from '..';
import { ExportEquipmentToCSV } from '../ExportEquipmentToCSV';
import { ImportEquipmentFromCSV } from '../ImportEquipmentFromCSV';
import { dataPaths } from '../constants';
import sharedStyles from '../styles.less';
import { useEquipment } from '../useEquipment';
import { AddEquipmentInput } from './AddEquipmentInput';
import { AnchorEquipment } from './AnchorEquipment';

const EquipmentStateOptions = {
  all: 'All',
  current: 'Show only available items',
  unselected: 'Show unavailable items',
} satisfies Record<ShowEquipmentState, string>;
type EquipmentHeaderProps = {
  setShowEquipmentState: (newState: ShowEquipmentState) => void;
  showEquipmentState: ShowEquipmentState;
};
export const EquipmentHeader = (props: EquipmentHeaderProps) => {
  const { getValues, getFieldState } = useAppForm();

  const [useMargin, setUseMargin] = useState(false);

  const { handleRemoveAll, handleOpenMedia, featureDetailsData } =
    useEquipment();
  const restrictions = getValues(dataPaths.restrictions);

  const { isDirty } = getFieldState(dataPaths.featureDetails);

  const haveCategoryItems = featureDetailsData.some(
    (category) => category.items.length > 0,
  );

  const handleDeleteAllButton = () => {
    handleRemoveAll();
  };

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Row>
        <Col flex="auto">
          {isDirty && (
            <div>
              Press <strong>&quot;Save&quot;</strong> button to apply the
              changes
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Space>
            <Select
              data-qa-selector="show-equipment-state"
              dropdownRender={(menu) => (
                <div data-qa-selector="show-equipment-state-dropdown">
                  {menu}
                </div>
              )}
              defaultValue={SHOW_EQUIPMENT_STATE.all}
              onChange={(value) => props.setShowEquipmentState(value)}
              style={{ width: 200 }}
            >
              {Object.values(SHOW_EQUIPMENT_STATE).map((state) => (
                <Select.Option key={state} value={state}>
                  {EquipmentStateOptions[state]}
                </Select.Option>
              ))}
            </Select>
            <ExportEquipmentToCSV />
          </Space>
        </Col>
        <Col>
          <Row className={sharedStyles.fullWidth}>
            <Col flex="none">
              <Space className={sharedStyles.fullWidth}>
                {restrictions.canAddFeatureDetail && <ImportEquipmentFromCSV />}
                <Button
                  onClick={handleOpenMedia}
                  data-qa-selector="open-media-btn"
                >
                  Open Media
                </Button>
                <Button
                  data-qa-selector="remove-all-button"
                  onClick={handleDeleteAllButton}
                  danger
                  type="primary"
                  disabled={
                    !haveCategoryItems || !restrictions.canRemoveFeatureDetail
                  }
                >
                  Remove all
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Affix
        offsetTop={124}
        onChange={(affixed) => {
          setUseMargin(affixed ?? false);
        }}
      >
        <div
          style={{
            background: 'white',
            padding: useMargin ? '0.6rem 1rem' : '1rem 15px',
            margin: useMargin ? '0 -1rem' : '0 -15px',
            boxShadow: useMargin
              ? '0 8px 10px -10px rgba(0, 0, 0, 0.2)'
              : undefined,
          }}
        >
          <Row>
            <Col span={24}>
              <AddEquipmentInput />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <AnchorEquipment />
            </Col>
          </Row>
        </div>
      </Affix>
    </Space>
  );
};
