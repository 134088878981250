import { Subsection } from '@components/Subsection';
import { Title } from './title';
import { Media } from './media';

export const ClassifiedsTab = () => {
  return (
    <div data-qa-selector="section-classifieds-tab">
      <Subsection
        title="Title"
        foldable
        renderable={false}
        foldBtnQaSelector="classified-description-fold-btn"
      >
        <Title />
      </Subsection>
      <Subsection
        title="Media"
        foldable
        renderable={false}
        foldBtnQaSelector="classified-media-fold-btn"
      >
        <Media />
      </Subsection>
    </div>
  );
};
