type SupportedCountries =
  | 'DE'
  | 'DE_AT'
  | 'ES'
  | 'FR'
  | 'FR_BE'
  | 'IT'
  | 'NL'
  | 'NL_BE'
  | 'PL'
  | 'SV_SE';

type LocalesNaming<T extends string> = T extends `${infer Head}_${infer Tail}`
  ? `${Lowercase<Head>}-${Uppercase<Tail>}`
  : `${Lowercase<T>}-${Uppercase<T>}`;

export type FeatureFlagsLocalesStruct = {
  [Key in SupportedCountries]: LocalesNaming<Key>;
};
export type FeatureFlagsLocales =
  FeatureFlagsLocalesStruct[keyof FeatureFlagsLocalesStruct];

export const FEATURE_FLAGS_LOCALES = {
  DE: 'de-DE', // http://www.localeplanet.com/icu/de-DE/index.html
  DE_AT: 'de-AT', // http://www.localeplanet.com/icu/de-AT/index.html
  ES: 'es-ES', // http://www.localeplanet.com/icu/es-ES/index.html
  FR: 'fr-FR', // http://www.localeplanet.com/icu/fr-FR/index.html
  FR_BE: 'fr-BE', // http://www.localeplanet.com/icu/fr-BE/index.html
  IT: 'it-IT', // http://www.localeplanet.com/icu/it-IT/index.html
  NL: 'nl-NL', // http://www.localeplanet.com/icu/nl-NL/index.html
  NL_BE: 'nl-BE', // http://www.localeplanet.com/icu/nl-BE/index.html
  PL: 'pl-PL', // http://www.localeplanet.com/icu/pl-PL/index.html
  SV_SE: 'sv-SE', // http://www.localeplanet.com/icu/sv-SE/index.html
} satisfies FeatureFlagsLocalesStruct;

type CountrySplitter<T extends string> = T extends `${string}_${infer Tail}`
  ? Tail
  : T;
export type FeatureFlagsCountriesStruct = {
  [Key in SupportedCountries as CountrySplitter<Key>]: CountrySplitter<Key>;
};
export type FeatureFlagsCountries =
  FeatureFlagsCountriesStruct[keyof FeatureFlagsCountriesStruct];
export const FEATURE_FLAGS_COUNTRIES = {
  DE: 'DE',
  AT: 'AT',
  ES: 'ES',
  FR: 'FR',
  BE: 'BE',
  IT: 'IT',
  NL: 'NL',
  PL: 'PL',
  SE: 'SE',
} satisfies FeatureFlagsCountriesStruct;

const flags = ['SPECS_SPOTLIGHT_SECTION', 'ADMGMT_EXPORT_TO_CSV'] as const;

export type FeatureFlags = { [Key in (typeof flags)[number]]: Key };
export const FEATURE_FLAGS = flags.reduce<FeatureFlags>(
  (acc, item) => ({ ...acc, [item]: item }),
  {} as FeatureFlags,
);
