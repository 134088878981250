import { FeatureDetailItem } from '@gql_codegen/retail-types';
import { notification } from 'antd';
import { saveAs } from 'file-saver';

type exportEquipmentCsvProps = {
  equipment: FeatureDetailItem[];
  adId: string;
};
export const exportEquipmentToCsv = ({
  equipment,
  adId,
}: exportEquipmentCsvProps) => {
  const firstItem = equipment[0];
  if (!firstItem) return;

  const csvRows = equipment
    .map(
      (item) =>
        item.translations.map((translation) => translation.text).join(',') ||
        item.name,
    )
    .filter(Boolean);

  const csvString = csvRows.join('\r\n');

  const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });

  const fileName = `ad-mgmt-${adId ?? ''}-equipment-export-${new Date().toISOString()}.csv`;

  saveAs(csvBlob, fileName);

  notification.success({
    message: `${csvRows.length} equipment successfully downloaded`,
  });
};
