import {
  FEATURE_FLAGS,
  FEATURE_FLAGS_COUNTRIES,
  FEATURE_FLAGS_LOCALES,
} from '@featureFlags/constants';
import { useFeaturesFlagsQuery } from '@featureFlags/useFeaturesFlagsQuery';
import useAdId from '@hooks/useAdId';
import { useAppForm } from '@hooks/useAppForm';
import { usePageData } from '@hooks/usePageData';
import { Button } from 'antd';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { exportEquipmentToCsv } from './exportEquipmentToCsv';

export const ExportEquipmentToCSV = () => {
  const adId = useAdId();
  const { data: pageData } = usePageData();

  const adCountry = pageData?.adMgmt?.country ?? '';

  const country =
    FEATURE_FLAGS_COUNTRIES[
      adCountry as keyof typeof FEATURE_FLAGS_COUNTRIES
    ] ?? FEATURE_FLAGS_COUNTRIES.DE;

  const localeKey = Object.keys(FEATURE_FLAGS_LOCALES).find((key) =>
    key.includes(adCountry),
  );

  const locale = localeKey
    ? FEATURE_FLAGS_LOCALES[localeKey as keyof typeof FEATURE_FLAGS_LOCALES]
    : FEATURE_FLAGS_LOCALES.DE;

  const {
    data: featureFlags,
    isLoading: isFeatureFlagsLoading,
    isError: isFeatureFlagsError,
  } = useFeaturesFlagsQuery({
    country,
    locale,
    names: [FEATURE_FLAGS.ADMGMT_EXPORT_TO_CSV],
  });

  const { control } = useAppForm();
  const equipmentData = useWatch({
    control,
    name: 'adMgmt.autoheroAndWkda.featureDetails.data',
  });

  const equipmentToExport = useMemo(
    () => equipmentData.flatMap((category) => category.items),
    [equipmentData],
  );

  if (!adId || isFeatureFlagsLoading || isFeatureFlagsError || !featureFlags) {
    return <></>;
  }

  const isFeatureFlagEnabled = featureFlags.ADMGMT_EXPORT_TO_CSV;

  if (!isFeatureFlagEnabled) {
    return <></>;
  }

  const handleExport = () => {
    exportEquipmentToCsv({
      equipment: equipmentToExport,
      adId,
    });
  };

  return (
    <Button
      type="primary"
      onClick={handleExport}
      data-qa-selector="export-equipment-to-csv"
      disabled={!equipmentToExport.length}
      title="Export current equipment list to CSV"
    >
      Export to CSV
    </Button>
  );
};
